<script setup lang="ts">
import type { Fancybox as FancyboxType } from '@fancyapps/ui/types'
import * as Fancyapps from '@fancyapps/ui'
const Fancybox: typeof FancyboxType = Fancyapps.Fancybox

const props = defineProps<{
	options?: any,
	onChange?: (index: number) => void,
	onClose?: () => void
	isEnabled?: boolean
}>()

const container = ref(null)

const onInit = () => {
	Fancybox.bind(container.value, '[data-fancybox]', {
		...(props.options || {}),
		on: {
			'Carousel.change': (fancybox) => {
				if (props.onChange) {
					props.onChange(fancybox.getSlide().index)
				}
			},
			'close': () => {
				if (props.onClose) {
					props.onClose()
				}
			}
		}
	})
}

onMounted(() => {
	if (props.isEnabled) {
		onInit()
	}
})

onUpdated(() => {
	if (props.isEnabled) {
		Fancybox.unbind(container.value)
		Fancybox.close()
		
		onInit()
	}
})

onUnmounted(() => {
	if (props.isEnabled) {
		Fancybox.destroy()
	}
})
</script>

<template>
	<div ref="container">
		<slot></slot>
	</div>
</template>

<style>
@import '@fancyapps/ui/dist/fancybox/fancybox.css';
</style>